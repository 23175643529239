import React, { useEffect, useState } from 'react';

const AirportAutofill = ({
	name,
	iataCode,
	handleClick,
	stateCode,
	heightRefs,
	index,
	currentHighlighted,
	setCurrentHighlighted,
}) => {
	const [isHighlighted, setIsHighlighted] = useState(false);
	useEffect(() => {
		if (currentHighlighted === index) {
			setIsHighlighted(true);
		} else {
			setIsHighlighted(false);
		}
	}, [currentHighlighted]);

	return (
		<div
			onMouseOver={(e) => {
				setCurrentHighlighted(index);
			}}
			onMouseLeave={(e) => {
				setCurrentHighlighted(-1);
			}}
			ref={(el) => (index < 5 ? (heightRefs.current[index] = el) : null)}
			className={`autofill-option${isHighlighted ? ' highlighted' : ''}`}
			onClick={() => {
				handleClick();
			}}>
			<p className='autofill-airport-name capitalize'>
				{iataCode} - {name}
				{/* {stateCode && `, ${stateCode}`} */}
			</p>
		</div>
	);
};

export default AirportAutofill;
