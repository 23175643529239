import React, { Component, Fragment } from 'react';
import {
	IoIosCheckmarkCircle,
	IoIosArrowForward,
	IoIosRadioButtonOff,
} from 'react-icons/io';
export default class ProgressBar extends Component {
	render() {
		// const stages = ['CURATION FEE', 'TRIP PROFILE', 'CHECKOUT'];

		const returnButton = (index) => {
			switch (this.props.currentStage) {
				case 0:
					const shouldBeChecked =
						(this.props.optionStage === 0 && index === 0) ||
						(this.props.optionStage > 0 && index < 2) ||
						(this.props.optionStage === 6 && index === 2);
					const shouldBeBlue =
						(this.props.optionStage > 0 && index === 2) ||
						(this.props.optionStage === 6 && index === 3);

					if (shouldBeChecked) {
						return (
							<IoIosCheckmarkCircle
								style={{
									opacity: 1,
									color: 'blue',
									backgroundColor: 'white',
									borderRadius: '100%',
									marginLeft: '2.5rem',
								}}
							/>
						);
					}
					if (shouldBeBlue) {
						return (
							<IoIosRadioButtonOff
								style={{
									opacity: 1,
									color: 'white',
									backgroundColor: 'blue',
									borderRadius: '100%',
									marginLeft: '2.5rem',
								}}
							/>
						);
					}

					return (
						<IoIosRadioButtonOff
							style={{
								opacity: 1,
								marginLeft: '2.5rem',
							}}
						/>
					);

				default:
					return this.props.currentStage === index ? (
						<IoIosRadioButtonOff
							style={{
								opacity: 1,
								color: 'white',
								backgroundColor: 'blue',
								borderRadius: '100%',
								marginLeft: '2.5rem',
							}}
						/>
					) : (
						<Fragment key={`progress-bar-${index}`}>
							{index > this.props.currentStage ? (
								<IoIosRadioButtonOff
									style={{
										opacity: 1,
										marginLeft: '2.5rem',
									}}
								/>
							) : (
								<IoIosCheckmarkCircle
									style={{
										opacity: 1,
										color: 'blue',
										backgroundColor: 'white',
										borderRadius: '100%',
										marginLeft: '2.5rem',
									}}
								/>
							)}
						</Fragment>
					);
			}
		};

		const mapStages = () => {
			return this.props.stages.map((stage, index) => {
				return (
					<Fragment key={`progress-bar-${index}`}>
						{/* {this.props.currentStage === index ? (
							<IoIosRadioButtonOff
								style={{
									opacity: 1,
									color: 'white',
									backgroundColor: 'blue',
									borderRadius: '100%',
									marginLeft: '2.5rem',
								}}
							/>
						) : (
							<Fragment key={`progress-bar-${index}`}>
								{index > this.props.currentStage ? (
									<IoIosRadioButtonOff
										style={{
											opacity: 1,
											marginLeft: '2.5rem',
										}}
									/>
								) : (
									<IoIosCheckmarkCircle
										style={{
											opacity: 1,
											color: 'blue',
											backgroundColor: 'white',
											borderRadius: '100%',
											marginLeft: '2.5rem',
										}}
									/>
								)}
							</Fragment>
						)} */}
						{returnButton(index)}
						<h4
							onClick={() => {
								if (this.props.currentStage === 0) {
									this.props.setOptionStage(0);
								}
								this.props.setStage(index);
							}}>
							{stage}
						</h4>
						{index < this.props.stages.length - 1 && (
							<IoIosArrowForward
								style={{
									opacity: this.props.currentStage >= index ? 1 : 0.45,
								}}
							/>
						)}
					</Fragment>
				);
			});
		};

		const mapButtons = () => {
			return (
				this.props.buttons &&
				this.props.buttons.length && (
					<nav className='float-right'>
						{this.props.buttons.map((button) => {
							return button;
						})}
					</nav>
				)
			);
		};

		return (
			<div className='newcss-progress-bar'>
				<section>
					<div className='newcss-progress-bar-grid'>{mapStages()}</div>
					{mapButtons()}
				</section>
			</div>
		);
	}
}
