import React, { useEffect, useState } from 'react';
import AirportInputUnlocked from '../../Inputs/AirportInputUnlocked';
import TripDate from './TripDate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	addDate,
	addFlight,
	editFlightDetail,
	editSuggestedHotel,
	removeFlight,
	addEmptyFlight,
	updateFlightDetails,
} from '../../../redux/actions/trip';
import moment from 'moment';
import {
	addFlightErrorObject,
	setFlightError,
	setReturnDateError,
} from '../../../redux/actions/errors';
import {
	capitalize,
	returnAirportCityData,
	returnTrueDate,
} from '../../../Utilities/helperFunctions';
const airports = require('../../../Utilities/new_airport_codes.json');

const QuickTripFlight = ({
	addDate,
	addFlight,
	returnDate,
	editFlightDetail,
	flightDetails,
	editSuggestedHotel,
	flightErrors,
	setFlightError,
	returnDateError,
	setReturnDateError,
	addEmptyFlight,
	name,
	handleSetCurrent,
	handleSectionValidity,
	addFlightErrorObject,
}) => {
	// const [returnDateError, setReturnDateError] = useState('');
	const [departureError, setDepartureError] = useState('');
	const [isValid, setIsValid] = useState(false);

	const flightDetail = flightDetails[0];
	const flightDetailErrors = flightErrors[0];

	const [tripData, setTripData] = useState({
		departure: null,
		destination: null,
		departureDate: '',
	});

	const [tripInputs, setTripInputs] = useState({
		departure: '',
		destination: '',
		departureDate: '',
	});

	const [tripErrors, setTripErrors] = useState({
		departure: '',
		destination: '',
		departureDate: '',
	});

	const errorStrings = {
		departure: 'Please select a valid location from the drop down menu',
		destination: 'Please select a valid location from the drop down menu',
		departureDate: 'Please select a valid date from the drop down menu',
	};

	const handleDate = (date) => {
		if (date instanceof Date) {
			return date.toISOString().slice(0, 10);
		}
		return date.slice(0, 10);
	};
	const clearFlight = (name) => {
		setTripInputs({
			...tripInputs,
			[name]: '',
		});
		setTripData({
			...tripData,
			[name]: null,
		});
	};

	const clearError = (name) => {
		setTripErrors({
			...tripErrors,
			[name]: '',
		});
	};
	const addData = (property, data) => {
		setTripData({
			...tripData,
			[property]: data,
		});
	};

	const handleChange = (e) => {
		setTripInputs({
			...tripInputs,
			[e.target.name]: e.target.value,
		});
	};

	const setFlightInput = (inputName, values) => {
		setTripInputs({
			...tripInputs,
			[inputName]: `${values.name} - ${values.iataCode}`,
		});
	};

	const handleFlight = (data) => {
		const { departure, destination, departureDate } = flightDetails[0] || {};
		const validFlight =
			flightDetails[0] && departure && destination && departureDate;
		for (let key in tripData) {
			if (!tripData[key]) {
				console.log('setting trip error 1');
				setTripErrors({
					...tripErrors,
					[key]: errorStrings[key],
				});
			}
		}

		if (validFlight) {
			addFlight(data);
		}
	};

	const returnAirportInfo = (string) => {
		const foundItem =
			airports && airports.length
				? airports.find((item) => item.iata === string.toLowerCase())
				: null;
		if (foundItem) {
			const airport = foundItem.airportName;
			return `${string.toUpperCase()} - ${capitalize(airport)} `;
		} else return string;
	};

	useEffect(() => {
		if (flightDetail) {
			setTripInputs({
				...tripInputs,
				...flightDetails[0],
				departure: returnAirportInfo(flightDetails[0]?.departure),
			});
		}
		if (!flightErrors[0]) {
			addFlightErrorObject();
		}
		handleSetCurrent(name);
		return () => {
			handleSetCurrent('');
		};
	}, []);

	useEffect(() => {
		let isCurrentlyValid = true;
		const formattedReturnDate = returnDate && new Date(returnDate);
		const formattedDepartureDate =
			tripInputs.departureDate && new Date(tripInputs.departureDate);

		isCurrentlyValid =
			formattedReturnDate &&
			formattedDepartureDate &&
			formattedReturnDate > formattedDepartureDate &&
			isCurrentlyValid;
		isCurrentlyValid =
			tripInputs.departure && tripInputs.destination && isCurrentlyValid;

		handleSectionValidity(name, isCurrentlyValid);
	}, [tripInputs, returnDate]);

	useEffect(() => {
		if (
			flightDetail &&
			flightDetail.destination &&
			flightDetail.departureDate
		) {
			editSuggestedHotel({
				city: returnAirportCityData(flightDetail),
				startDate: moment(returnTrueDate(flightDetail.departureDate))
					.format()
					.slice(0, 10),
				flightIndex: 0,
			});
		}
		if (returnDate)
			editSuggestedHotel({
				endDate: moment(returnTrueDate(returnDate)).format().slice(0, 10),
				flightIndex: 0,
			});

		let isCurrentlyValid = true;
		const formattedReturnDate = returnDate && new Date(returnDate);
		const formattedDepartureDate =
			flightDetail &&
			flightDetail.departureDate &&
			new Date(flightDetail.departureDate);

		isCurrentlyValid =
			formattedReturnDate &&
			formattedDepartureDate &&
			formattedReturnDate > formattedDepartureDate &&
			isCurrentlyValid;
		isCurrentlyValid =
			flightDetail &&
			flightDetail.departure &&
			flightDetail.destination &&
			isCurrentlyValid;

		handleSectionValidity(name, isCurrentlyValid);
	}, [flightDetails[0], returnDate]);

	return (
		<section className={`trip__form-card`}>
			<h2 className='trip__form-card-header'>FLIGHT</h2>
			<div className='round-trip__section'>
				<TripDate
					roundTrip={true}
					cname='round-trip__departure-date'
					handleChange={handleChange}
					label='Departure Date'
					name='departureDate'
					value={tripInputs.departureDate}
					index={0}
					addDate={(e) => {
						editFlightDetail(0, e.target.name, e.target.value);
						editSuggestedHotel({
							flightIndex: 0,
							startDate: e.target.value,
						});
					}}
					sortedFlightDetails={flightDetails}
					error={flightDetailErrors && flightDetailErrors.departureDate}
					clearError={() => setFlightError(0, 'departureDate', '')}
					addError={() => {
						console.log('setting trip error 2');
						setFlightError(0, 'departureDate', errorStrings.departureDate);
					}}
				/>
				<TripDate
					cname='round-trip__return-date'
					handleChange={(e) => {
						e.preventDefault();
						addDate(e.target.name, e.target.value);
					}}
					label='Return Date'
					name='returnDate'
					sortedFlightDetails={flightDetails}
					value={returnDate ? handleDate(returnDate) : ''}
					error={returnDateError}
					clearError={() => setReturnDateError('')}
					addError={() =>
						setReturnDateError(
							'Please select a valid date from the drop down menu'
						)
					}
				/>
				<div className='round-trip__departure'>
					<label htmlFor={`originLocationCode`} className='active'>
						Departure (Airport)
					</label>

					<AirportInputUnlocked
						setTripInputs={setTripInputs}
						tripInputs={tripInputs}
						inputKey={`roundTripFlightDetail-departure`}
						value={tripInputs.departure}
						name='departure'
						addFlight={addData}
						handleChange={handleChange}
						setFlightInput={setFlightInput}
						detailIndex={0}
						error={flightDetailErrors && flightDetailErrors.departure}
						clearError={() => setDepartureError('')}
						clearFlight={clearFlight}
						addError={() => setDepartureError(errorStrings.departure)}
					/>
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => ({
	returnDate: state.trip.returnDate,
	flightDetails: state.trip.flightDetails,
	flightErrors: state.errors.flightErrors,
	returnDateError: state.errors.returnDateError,
});

export default connect(mapStateToProps, {
	editFlightDetail,
	addDate,
	addFlight,
	editSuggestedHotel,
	setFlightError,
	setReturnDateError,
	addEmptyFlight,
	addFlightErrorObject,
})(QuickTripFlight);
