import React from 'react';
import StripePayment from './StripePayment';

const PaymentWindow = ({ submitAuthorization }) => {
	return (
		<section className='payment-window'>
			<div className='fee-description'>
				<h2>$35 Designer Fee</h2>

				<dl>
					<dt>✓</dt>
					<dd>
						$55 flat fee launches a travel advisor to create your customized
						trip based on your preferences and trip profile.
					</dd>
					<dt>✓</dt>
					<dd>
						You will receive hand selected hotels, flights, activities, and
						transportation options based on your preferences.
					</dd>
				</dl>
			</div>
			<StripePayment submitAuthorization={submitAuthorization} />
		</section>
	);
};

export default PaymentWindow;
