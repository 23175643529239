import React, { useEffect, useRef, useState } from 'react';
import FaqItem from './FaqItem';
import { motion } from 'framer-motion';

const FaqMain = ({ content, current }) => {
	const refs = useRef([]);
	const ref = useRef(null);
	const [mainHeight, setMainHeight] = useState('');

	useEffect(() => {
		if (refs.current.length) {
			let calculatedHeight = 0;
			let rems = 0;

			for (let i = 0; i < 6; i++) {
				if (refs.current[i]) {
					calculatedHeight = calculatedHeight + refs.current[i].clientHeight;
					rems += 2.5;
				}
			}
			setMainHeight(`calc(${calculatedHeight}px + ${rems}rem)`);
		}
	}, [refs]);
	return (
		<motion.main
			initial={{ x: '100%', opacity: 0 }}
			animate={{ transition: '100ms', x: 0, opacity: 1 }}
			transition={{ x: { type: 'spring', bounce: 0 } }}
			exit={{ x: '100%', opacity: 0 }}
			ref={(el) => (ref.current = el)}
			// style={{ height: mainHeight || 'auto' }}
			className='faq__main'>
			{content &&
				content.map((item, index) => {
					return (
						<FaqItem
							key={'faq-item-' + current + '-' + index}
							refs={refs}
							index={index}
							item={item}
						/>
					);
				})}
		</motion.main>
	);
};

export default FaqMain;
