import React, { useState } from 'react';
import TripForm from './TripForm';
import PaymentDetails from '../CheckoutNew/PaymentDetails';
import ProgressBar from '../ProgressBar';
import PricingCard from '../landing/PricingCard';
import Success from './Success';
import { connect } from 'react-redux';
import { clearTrip, createNewTrip } from '../../redux/actions/trip';
import { submitTrip, updateTripPurchase } from '../../redux/actions/auth';
import ReactGA from 'react-ga';
import PlanTripVideo from './PlanTripVideo';
import TripOptions from './TripOptions';
import uuid from 'react-uuid';

const CreateTripShowContainer = ({
	stage,
	handleStage,
	errors,
	addTrip,
	trip,
	createNewTrip,
	submitTrip,
	clearTrip,
	setStage,
	errorSection,
	setErrorSection,
	isAdmin,
	isNewTrip,
	updateTripPurchase,
}) => {
	const [success, setSuccess] = useState(false);
	const [optionStage, setOptionStage] = useState(0);
	const [defaultOpen, setDefaultOpen] = useState(isNewTrip);
	const submitAuthorization = async (curationInfo) => {
		const newSubmitted = {
			success: true,
			on: new Date(),
		};
		let updatedPurchaseInfo;
		updatedPurchaseInfo = {
			...trip.purchase,
			curation: curationInfo,
		};

		try {
			const TRACKING_ID = process.env.REACT_APP_GA_PROPERTY_ID;
			ReactGA.initialize(TRACKING_ID);
			ReactGA.event({
				category: 'User_Action',
				action: 'authorize_curation_fee',
				label: 'create_trip_page',
			});

			const submitSuccess = await submitTrip({
				...trip,
				submitted: newSubmitted,
				purchase: updatedPurchaseInfo,
			});

			if (submitSuccess) {
				setSuccess(true);
				clearTrip();
			}
		} catch (err) {
			console.log(err);
		}
	};
	return success === false ? (
		<div className='create-trip__container'>
			<ProgressBar
				currentStage={stage}
				setStage={handleStage}
				addTrip={addTrip}
				stages={['PROFILE OPTION', 'CURATION FEE', 'TRIP PROFILE', 'CHECKOUT']}
				optionStage={optionStage}
				setOptionStage={setOptionStage}
				buttons={[
					<PlanTripVideo
						setDefaultOpen={setDefaultOpen}
						defaultOpen={isNewTrip && stage === 1}
					/>,
				]}
			/>
			{stage === 0 && (
				<TripOptions
					createNewTrip={createNewTrip}
					trip={trip}
					setStage={setStage}
					optionStage={optionStage}
					setOptionStage={setOptionStage}
					submitAuthorization={submitAuthorization}
				/>
			)}

			{stage === 1 && (
				<PricingCard
					tripForm={true}
					closePricing={() => {
						!trip._id && createNewTrip();
						setStage(2);
					}}
				/>
			)}

			{stage === 2 && (
				<TripForm
					errorSection={errorSection}
					errors={errors}
					isAdmin={isAdmin}
					setSuccess={() => setSuccess(true)}
					nextStage={addTrip}
					setErrorSection={setErrorSection}
				/>
			)}

			{stage === 3 && (
				<PaymentDetails
					handleClick={submitAuthorization}
					setSuccess={() => setSuccess(true)}
					curation={true}
				/>
			)}
		</div>
	) : (
		<Success userId={isAdmin && trip.user._id} />
	);
};

const mapStateToProps = (state) => ({
	errors: state.errors,
	trip: state.trip,
	user: state.auth.user,
});

export default connect(mapStateToProps, {
	createNewTrip,
	submitTrip,
	clearTrip,
	updateTripPurchase,
})(CreateTripShowContainer);
