import React, { Fragment, useEffect } from 'react';
import UserComments from './UserComments';
import ActivityTypes from './ActivityTypes';
import TripTypes from './TripTypes';

const TripType = ({
	hasError,
	travelType,
	activityThemes,
	setErrorSection,
	errorSection,
	name,
	handleSetCurrent,
	handleSectionValidity,
}) => {
	useEffect(() => {
		let tripDetailValid = true;
		if (!travelType) {
			tripDetailValid = false;
		}
		if (!activityThemes || !activityThemes.length) {
			tripDetailValid = false;
		}

		if (hasError && tripDetailValid) {
			if (tripDetailValid) setErrorSection({ ...errorSection, hotel: false });
		} else if (name && handleSectionValidity) {
			handleSectionValidity(name, tripDetailValid);
		}
	}, [travelType, activityThemes]);

	useEffect(() => {
		if (name && handleSetCurrent) {
			handleSetCurrent(name);
			return () => {
				handleSetCurrent('');
			};
		}
	}, []);

	return (
		<Fragment>
			<section className={`trip__form-card${hasError ? ' error' : ''}`}>
				<h2 className='trip__form-card-header'>TRIP DETAILS</h2>
				<TripTypes />
				<ActivityTypes />
				<UserComments />
			</section>
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	travelType: state.trip.travelType,
	activityThemes: state.trip.activityThemes,
});

export default TripType;
