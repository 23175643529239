import React, { useEffect, useState } from 'react';
import QuickTripOptions from './QuickTripOptions';
import QuickTripFlight from './flights/QuickTripFlight';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import TravelerAmountSection from './secondary-travelers/TravelerAmountSection';
import ExtraSupport from './type-of-travel/ExtraSupport';
import TripType from './type-of-travel/TripType';
import PaymentWindow from '../stripe/PaymentWindow';
import {
	addProfileType,
	editHotel,
	setFlightType,
} from '../../redux/actions/trip';
import { connect } from 'react-redux';

const TripOptions = ({
	setStage,
	addProfileType,
	setFlightType,
	optionStage,
	setOptionStage,
	trip,
	submitAuthorization,
	editHotel,
}) => {
	const [sectionValidity, setSectionValidity] = useState({
		flight: false,
		amount: false,
		tripType: false,
		support: false,
	});

	const [currentSection, setCurrentSection] = useState('');

	const handleSetCurrent = (name) => {
		setCurrentSection(name);
	};

	const handleSectionValidity = (name, value) => {
		setSectionValidity({
			...sectionValidity,
			[name]: value,
		});
	};

	const calculateRooms = () => {
		console.log('adults', trip.adults);
		console.log('children', trip.children);
		console.log('infants', trip.infants);
		let amount = trip.adults + trip.children + trip.infants;
		console.log('amount', amount);
		amount = amount / 2;
		amount = Math.ceil(amount);
		editHotel(0, 'rooms', amount);
	};

	return (
		<div className='trip-options__main'>
			<div className='action-left'>
				<button
					onClick={() => setOptionStage(optionStage - 1)}
					style={{
						visibility: optionStage === 0 ? 'hidden' : 'initial',
					}}>
					<IoIosArrowBack />
				</button>
			</div>
			{optionStage === 0 && (
				<ul>
					<li>
						<button
							onClick={() => {
								addProfileType('tailor-made');
								setStage(1);
							}}>
							Tailor Made
						</button>
					</li>
					<li>
						<button
							onClick={() => {
								addProfileType('agents-choice');
								setFlightType('round-trip');
								setOptionStage(1);
							}}>
							Agents Choice
						</button>
					</li>
				</ul>
			)}
			{optionStage === 1 && (
				<QuickTripOptions trip={trip} setOptionStage={setOptionStage} />
			)}
			{optionStage === 2 && (
				<QuickTripFlight
					name='flight'
					handleSetCurrent={handleSetCurrent}
					handleSectionValidity={handleSectionValidity}
				/>
			)}
			{optionStage === 3 && (
				<TravelerAmountSection name='amount' calculateRooms={calculateRooms} />
			)}

			{optionStage === 4 && (
				<TripType
					name='tripType'
					travelType={trip.travelType}
					activityThemes={trip.activityThemes}
					sectionValidity={sectionValidity}
					handleSetCurrent={handleSetCurrent}
					handleSectionValidity={handleSectionValidity}
				/>
			)}
			{optionStage === 5 && (
				<PaymentWindow submitAuthorization={submitAuthorization} />
			)}

			<div className='action-right'>
				<button
					style={{
						visibility: optionStage === 5 ? 'hidden' : 'initial',
					}}
					onClick={(isValid) => {
						if (
							currentSection &&
							sectionValidity[currentSection] !== null &&
							!sectionValidity[currentSection]
						) {
						} else {
							setOptionStage(optionStage + 1);
						}
					}}>
					<IoIosArrowForward />
				</button>
			</div>
		</div>
	);
};

export default connect(null, { addProfileType, setFlightType, editHotel })(
	TripOptions
);
