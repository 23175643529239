import React from 'react';
import PropTypes from 'prop-types';
import TripProfileFlight from './flights/TripProfileFlight';
import MultiDayTripSection from './multi-day/MultiDayTripSection';
import DayTripSection from './single-day/DayTripSection';
import TravelerAmountSection from './secondary-travelers/TravelerAmountSection';
import TripType from './type-of-travel/TripType';
import ExtraSupport from './type-of-travel/ExtraSupport';
import { connect } from 'react-redux';
import { setRedirect } from '../../redux/actions/redirect';
import { submitItineraryUpdate } from '../../Utilities/api';

const TripForm = ({
	errors,
	nextStage,
	errorSection,
	setErrorSection,
	trip,
	setSuccess,
	isAdmin,
}) => {
	const adminToken = localStorage.getItem('adminToken');

	const adminSave = () => {
		submitItineraryUpdate(trip, [setSuccess]);
	};

	return trip._id ? (
		<div className='newcss-center-left'>
			<h1 className='trip__form-title'>Trip Profile</h1>
			<p className='trip__form-subtext'>
				Fill out the form below so that we can help you plan a memorable trip.
			</p>
			<TripProfileFlight
				hasError={errorSection.flight}
				flightError={errors && errors.flights}
				returnDateError={errors && errors.returnDate}
				setErrorSection={setErrorSection}
				errorSection={errorSection}
			/>
			<MultiDayTripSection
				hasError={errorSection.hotel}
				setErrorSection={setErrorSection}
				errorSection={errorSection}
			/>
			<DayTripSection />
			<TravelerAmountSection />
			<ExtraSupport />
			<TripType
				hasError={errorSection.tripDetail}
				travelTypeError={errors && errors.travelType}
				activityError={errors && errors.activityThemes}
				setErrorSection={setErrorSection}
				errorSection={errorSection}
			/>
			<button
				onClick={
					isAdmin
						? () => nextStage(adminSave)
						: () => {
								nextStage();
						  }
				}
				className='newcss-cta-button'>
				{adminToken ? 'Submit' : 'Save'}
			</button>
		</div>
	) : null;
};

TripForm.propTypes = {};

const mapStateToProps = (state) => ({
	trip: state.trip,
});

export default connect(mapStateToProps, null)(TripForm);
