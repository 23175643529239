import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { get, post } from '../../Utilities/api';
import { FadeLoader } from 'react-spinners';
import { css } from '@emotion/core';

const override = css`
	display: block;
	margin: 5rem auto;
`;

const StripePayment = ({ submitAuthorization }) => {
	const [stripePromise, setStripePromise] = useState(null);
	const [stripePromiseLoading, setStripePromiseLoading] = useState(true);
	const [clientSecretLoading, setClientSecretLoading] = useState(true);
	const [clientSecret, setClientSecret] = useState(null);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const retrievePromise = async () => {
			const res = await get(`/api/v1/stripe/config`, {}, true);
			if (res.data) {
				setStripePromise(loadStripe(res.data.publishableKey));
				setStripePromiseLoading(false);
			}
		};
		retrievePromise();
		return () => {
			setStripePromise(null);
			setStripePromiseLoading(true);
		};
	}, []);

	useEffect(() => {
		const retrieveClient = async () => {
			const res = await post(`/api/v1/stripe/create-payment-intent`, {}, true);
			if (res.data) {
				setClientSecret(res.data.clientSecret);
				setClientSecretLoading(false);
			}
		};

		retrieveClient();
		return () => {
			setClientSecret(null);
			setClientSecretLoading(true);
		};
	}, []);

	useEffect(() => {
		if (!stripePromiseLoading && !clientSecretLoading) {
			setLoading(false);
		}
		return () => {
			setLoading(true);
		};
	}, [stripePromiseLoading, clientSecretLoading]);

	if (!loading) {
		if (clientSecret && stripePromise) {
			return (
				<Elements stripe={stripePromise} options={{ clientSecret }}>
					<CheckoutForm submitAuthorization={submitAuthorization} />
				</Elements>
			);
		} else {
			return null;
		}
	} else {
		return (
			<div className='loading-icon__container'>
				<FadeLoader css={override} size={250} color={'black'} loading={true} />
			</div>
		);
	}
};

export default StripePayment;
