import React, { useEffect, useState } from 'react';
import {
	addEmptyFlight,
	editFlightDetail,
	removeFlight,
} from '../../redux/actions/trip';
import { connect } from 'react-redux';

const QuickTripOptions = ({
	addEmptyFlight,
	setOptionStage,
	trip,
	editFlightDetail,
	removeFlight,
}) => {
	const [tripOptions, setTripOptions] = useState([
		{
			IATA: 'CDG',
			'Airport Name': 'Paris Charles de Gaulle Airport',
			'Location Served': 'Paris, France',
			'City Code': 'PAR',
			'Alternate City': 'PAR',
			'City Name': 'PARIS',
			'State Code': 'NULL',
			'Country Code': 'FR',
			'Country Name': 'FRANCE',
		},
		{
			IATA: 'KEF',
			'Airport Name': 'Keflavík International Airport',
			'Location Served': 'Reykjavík, Iceland',
			'City Code': 'REK',
			'Alternate City': 'REK',
			'City Name': 'BORGARNESI',
			'State Code': 'NULL',
			'Country Code': 'IS',
			'Country Name': 'ICELAND',
		},
		{
			IATA: 'NAP',
			'Airport Name': 'Naples International Airport',
			'Location Served': 'Naples, Campania, Italy',
			'City Code': 'NAP',
			'Alternate City': '',
			'City Name': 'NAPLES',
			'State Code': 'NULL',
			'Country Code': 'IT',
			'Country Name': 'ITALY',
		},
		{
			IATA: 'SJU',
			'Airport Name': 'Luis Muñoz Marín International Airport',
			'Location Served': 'San Juan, Puerto Rico',
			'City Code': 'SJU',
			'Alternate City': '',
			'City Name': 'BAYAMON',
			'State Code': 'NULL',
			'Country Code': 'PR',
			'Country Name': 'PUERTO RICO',
		},
	]);

	return (
		<>
			<ul>
				{tripOptions.length ? (
					<>
						{tripOptions.map((option) => {
							return (
								<li>
									<button
										onClick={() => {
											if (trip.flightDetails.length) {
												editFlightDetail(0, 'destination', option['IATA']);
												for (
													let index = trip.flightDetails.length - 1;
													index > 0;
													index--
												) {
													removeFlight(index);
												}
											} else {
												addEmptyFlight('', option['IATA']);
											}
											setOptionStage(2);
										}}>
										{option['Location Served']}
									</button>
								</li>
							);
						})}
					</>
				) : null}
			</ul>
		</>
	);
};

export default connect(null, {
	addEmptyFlight,
	editFlightDetail,
	removeFlight,
})(QuickTripOptions);
