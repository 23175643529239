import React, { useEffect } from 'react';
import ErrMessage from '../../Layout/ErrMessage';
import { connect } from 'react-redux';
import { returnTrueDate } from '../../../Utilities/helperFunctions';

const TripDate = ({
	name,
	label,
	value,
	addDate,
	handleChange,
	error,
	cname,
	index,
	sortedFlightDetails,
	addError,
	clearError,
	returnDate,
	roundTrip,
	handleHotel,
}) => {
	const handleDateError = () => {
		if (
			name === 'departureDate' &&
			index === 0 &&
			returnTrueDate(value) < new Date()
		) {
			console.log('adding error 1');
			addError('Please choose a date at least a day in advance.');
		}
		if (
			name === 'returnDate' &&
			sortedFlightDetails &&
			value &&
			sortedFlightDetails[sortedFlightDetails.length - 1] &&
			sortedFlightDetails[sortedFlightDetails.length - 1].departureDate &&
			returnTrueDate(
				sortedFlightDetails[sortedFlightDetails.length - 1].departureDate
			) > returnTrueDate(value)
		) {
			console.log('adding error 2');
			addError();
		}

		const prev =
			index > 0 && sortedFlightDetails.find((item) => item.index === index - 1);
		if (
			name === 'departureDate' &&
			index > 0 &&
			prev &&
			prev.departureDate &&
			returnTrueDate(prev.departureDate) > returnTrueDate(value)
		) {
			console.log('adding error 3');
			addError();
		}
	};

	const handleRemoveError = () => {
		const currentTrueValue = returnTrueDate(value);
		if (error) {
			if (name === 'departureDate' && value) {
				if (
					roundTrip &&
					returnDate &&
					currentTrueValue < returnTrueDate(returnDate)
				) {
					clearError();
				}

				if (index === 0 && currentTrueValue > new Date()) {
					clearError();
				}
			}

			const isDepartureDate = name === 'departureDate';
			if (
				roundTrip &&
				isDepartureDate &&
				currentTrueValue < returnTrueDate(returnDate) &&
				currentTrueValue > new Date()
			) {
				clearError();
			}

			const prev =
				index > 0 &&
				sortedFlightDetails.find((item) => item.index === index - 1);
			if (
				name === 'departureDate' &&
				index > 0 &&
				prev &&
				prev.departureDate &&
				returnTrueDate(prev.departureDate) < currentTrueValue
			) {
				clearError();
			}

			if (
				name === 'returnDate' &&
				sortedFlightDetails &&
				value &&
				returnTrueDate(
					sortedFlightDetails[sortedFlightDetails.length - 1].departureDate
				) < currentTrueValue
			) {
				clearError();
			}
			// const validDepartureDate =
			// 	(!roundTrip &&
			// 		index > 0 &&
			// 		returnTrueDate(sortedFlightDetails[index - 1].departureDate) <
			// 			returnTrueDate(value)) ||
			// 	(index === 0 && returnTrueDate(value) > new Date());
			// if (validDepartureDate) {
			// 	clearError();
			// }
			// if (
			// 	name === 'returnDate' &&
			// 	returnTrueDate(
			// 		sortedFlightDetails[sortedFlightDetails.length - 1].departureDate
			// 	) < returnTrueDate(value)
			// )
			// 	clearError();
		}
	};

	useEffect(() => {
		if (value && sortedFlightDetails) {
			if (error) {
				handleRemoveError();
			} else {
				handleDateError();
			}
		}

		if (
			roundTrip &&
			name === 'departureDate' &&
			returnTrueDate(value) < returnTrueDate(returnDate)
		) {
			clearError();
		}
		if (name === 'departureDate' && index > 0) {
			const prevIndex = sortedFlightDetails.find(
				(flight) => flight.index === index - 1
			);

			console.log('prevIndex', prevIndex);
			let prevDepartureDate =
				typeof prevIndex.departureDate === 'string'
					? prevIndex.departureDate
					: prevIndex.departureDate.toISOString();
			const isGreaterDate =
				returnTrueDate(prevDepartureDate) < returnTrueDate(value);
			if (!isGreaterDate) {
				console.log('adding error 4');
				addError();
			}
		}
	}, [sortedFlightDetails, value, returnDate]);
	return (
		<div className={cname}>
			<label htmlFor={name} className='active'>
				{label}
			</label>
			<input
				data-type='tripProfileFlightInputs'
				onBlur={(e) => {
					e.preventDefault();
					if (addDate) {
						addDate(e);
					}
				}}
				name={name}
				placeholder='yyyy-mm-dd'
				type='date'
				value={value}
				onChange={(e) => {
					e.preventDefault();
					handleChange(e);
					handleHotel && handleHotel({ [e.target.name]: e.target.value });
					// handleDateError(e);
					// handleRemoveError(e);
				}}
			/>
			{error ? <ErrMessage text={error} /> : null}
		</div>
	);
};

const mapStateToProps = (state) => ({
	flightDetails: state.trip.flightDetails,
	returnDate: state.trip.returnDate,
});

export default connect(mapStateToProps, null)(TripDate);
