import React, { Fragment, useEffect, useState } from 'react';
import PlanTripNavbar from '../Itinerary/PlanTripNavbar';
import { getTrip, submitUpdate } from '../../redux/actions/trip';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import requireAuth from '../hoc/AuthComponent';
import PlanTripMain from '../PlanTrip/PlanTripMain';
import ItineraryModal from '../ItinerarySidenav/ItineraryModal';
import { useParams } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import ToolTipModal from '../tooltips/ToolTipModal';

const PlanTrip = ({ getTrip, trip, user }) => {
	const [selected, setSelected] = useState('Flights');
	const [itemDetails, setItemDetails] = useState(null);
	const [modalOpen, setModalOpen] = useState(true);
	const [toolTipOpen, setToolTipOpen] = useState(false);
	const [detailModalOpen, setDetailModalOpen] = useState(false);
	const { id } = useParams();

	const toggleModal = () => setModalOpen(!modalOpen);

	const handleSelected = (name) => {
		setSelected(name);
	};

	const setDetails = (details) => {
		setItemDetails(...details);
	};
	useEffect(() => {
		getTrip(id);
	}, [getTrip, id]);

	useEffect(() => {
		if (user.showToolTips) {
			setToolTipOpen(true);
		}
	}, [user._id]);

	return trip._id ? (
		<div className='itinerary'>
			<Fragment>
				<AnimatePresence initial={true} mode='wait'>
					{toolTipOpen ? (
						<ToolTipModal
							showToolTips={user.showToolTips}
							user={user}
							closeModal={() => {
								setToolTipOpen(false);
							}}
						/>
					) : null}
				</AnimatePresence>
				<PlanTripNavbar
					category={null}
					cart={trip.cart}
					openToolTip={() => setToolTipOpen(true)}
				/>
				<PlanTripMain
					trip={trip}
					selected={selected}
					handleSelected={handleSelected}
					toggleModal={toggleModal}
					modalOpen={modalOpen}
					setDetails={setDetails}
					itemDetails={itemDetails}
				/>
				<AnimatePresence initial={false}>
					<ItineraryModal
						detailModalOpen={detailModalOpen}
						toggleDetailModal={() => setDetailModalOpen(false)}
						selectedItem={itemDetails}
					/>
				</AnimatePresence>
			</Fragment>
		</div>
	) : null;
};

PlanTrip.propTypes = {
	getTrip: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
	trip: state.trip,
	user: state.auth.user,
});

export default connect(mapStateToProps, { getTrip, submitUpdate })(
	requireAuth(PlanTrip)
);
