import moment from 'moment';
import { findCity } from './findCityCode';

const airports = require('./new_airport_codes.json');

export const isMultiDay = (start, end) => {
	const momentStart = moment(start);
	const momentEnd = moment(end);
	return (
		momentEnd.diff(momentStart, 'days') > 1 ||
		new Date(start).getDate() !== new Date(end).getDate()
	);
};

export const returnAirportCityData = (flightDetail) => {
	let servedLocation, countryName, cityName, city, cityCode;

	const foundLocation = airports.find(
		(item) => item.iata === flightDetail.destination.toLowerCase()
	);
	if (foundLocation) {
		servedLocation = foundLocation.locationServed.split(', ');
		countryName = servedLocation[servedLocation.length - 1];
		cityName = servedLocation[0]
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '');
		cityCode = foundLocation.cityCode;
		city = findCity({
			cityName,
			countryName,
			cityCode,
		});
	}
	return city;
};

export const mergeSortedItems = (flights, hotels) => {
	let flightIndex = 0;
	let hotelIndex = 0;
	const results = [];
	while (flightIndex < flights.length && hotelIndex < hotels.length) {
		if (
			new Date(flights[flightIndex].departureDate) <=
			new Date(hotels[hotelIndex].startDate)
		) {
			results.push(flights[flightIndex]);
			flightIndex++;
		} else {
			results.push(hotels[hotelIndex]);
			hotelIndex++;
		}
	}
	if (flightIndex === flights.length) {
		return [...results, ...hotels.slice(hotelIndex)];
	} else {
		return [...results, ...flights.slice(flightIndex)];
	}
};

export const toDollarStyle = (number, totalCurrencyCode) => {
	var formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: totalCurrencyCode,

		// These options are needed to round to whole numbers if that's what you want.
		//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	});

	return formatter.format(number);
};

export const handleMultiday = (event) => {
	const isSameMonth = event.start.getMonth() === event.end.getMonth();
	const isSameDay = event.start.getDate() === event.end.getDate();

	return {
		isMultiDay: isSameDay && isSameMonth ? false : true,
		className: isSameDay && isSameMonth ? ' same-day' : '',
	};
};
export const returnNewDate = (date, time) => {
	const newDate = returnTrueDate(date);
	if (time) {
		const hours = time.slice(0, 2);
		const min = time.slice(3);
		newDate.setHours(hours);
		newDate.setMinutes(min);
	}
	return newDate;
};

export const returnTrueDate = (date, hours = 0, minutes = 0) => {
	if (typeof date === 'string') {
		const dateArray = date && date.split(/[T/-]/g);
		let year, month, day;
		if (date.includes('/')) {
			const today = new Date();
			const todaysYear = today.getFullYear().toString().slice(0, 2);
			month = dateArray && parseInt(dateArray[0], 10) - 1;
			day = dateArray && parseInt(dateArray[1], 10);
			year = dateArray && todaysYear + dateArray[2];
		} else {
			year = dateArray && dateArray[0];
			month = dateArray && parseInt(dateArray[1], 10) - 1;
			day = dateArray && dateArray[2];
		}
		return dateArray ? new Date(year, month, day, hours, minutes) : '';
	} else {
		return date;
	}
};

export const handleTime = (time) => {
	const hour = time && parseInt(time.slice(0, 2)) + 0;
	let antiPost = 'a.m.';
	let result = hour && hour.toString();
	if (hour > 12) {
		result = (hour - 12).toString();
		antiPost = 'p.m.';
	}
	if (hour >= 12) {
		antiPost = 'p.m.';
	}
	result = time ? result + time.slice(2) : result;
	return `${result} ${antiPost}`;
};

export const returnKey = ({
	lodging,
	transportation,
	flight,
	activity,
	amadeusCars,
}) => {
	if (lodging) return 'lodging';
	if (transportation) return 'transport';
	if (flight) return 'flights';
	if (activity) return 'activities';
	if (amadeusCars) return 'amadeusCars';
};

export function returnItemFields(item, keys) {
	const foundKeys = [];
	let keyMap = {};
	keys.forEach((key) => {
		keyMap[key] = true;
	});
	const results = {};

	const returnKeyValues = (item, currentKey) => {
		if (foundKeys.length === keys.length) {
			return null;
		}
		if (keyMap[currentKey] && !results[currentKey]) {
			results[currentKey] = item;
			foundKeys.push(currentKey);
			return null;
		}
		if (Array.isArray(item)) {
			for (let index of item) {
				returnKeyValues(index);
			}
		} else if (typeof item === 'object') {
			for (let key in item) {
				returnKeyValues(item[key], key);
			}
		} else return null;
	};

	returnKeyValues(item);

	return results;
}

export const useCheckType = (type) => {
	const activity =
		type === 'individual-activity' || type === 'group-activity'
			? 'activities'
			: false;
	const transportation =
		type === 'public-transport' || type === 'private-transport'
			? 'transport'
			: false;
	const lodging = type === 'hotel-offer' ? 'lodging' : false;
	const amadeusCars = type === 'amadeus-car' ? 'amadeusCars' : false;
	const flight = type === 'flight-offer' ? 'flights' : false;
	const key = activity || flight || lodging || transportation || amadeusCars;
	const booking = type === 'booking';
	const curation = type === 'curation';
	const custom = type === 'custom-event';
	return {
		activity,
		flight,
		lodging,
		transportation,
		booking,
		curation,
		amadeusCars,
		key,
		custom,
	};
};

export function useCheckItemStatus(trip, item) {
	const { paid, authorized, cart, selected } = trip;
	const { activity, flight, lodging, transportation, amadeusCars } =
		useCheckType(item && (item.type || item.data.type || null));
	const checkStatusObject = (object, key, id, groupKey) => {
		// if (amadeusCars) {
		// 	const stringified = JSON.stringify(object.data);
		// 	return object;
		// }
		if (object && object[key].find((item) => item._id && item._id === id))
			return groupKey;
		else return false;
	};
	const checkAllStatus = (key) => {
		const itemPaid = checkStatusObject(paid, key, item._id, 'paid');
		const itemAuthorized = checkStatusObject(
			authorized,
			key,
			item._id,
			'authorized'
		);
		const itemSelected = checkStatusObject(selected, key, item._id, 'selected');
		const itemInCart = checkStatusObject(cart, key, item._id, 'cart');

		return { itemPaid, itemAuthorized, itemSelected, itemInCart };
	};
	if (activity) {
		return checkAllStatus('activities');
	} else if (flight) {
		return checkAllStatus('flights');
	} else if (lodging) {
		return checkAllStatus('lodging');
	} else if (transportation) {
		return checkAllStatus('transport');
	} else if (amadeusCars) {
		return checkAllStatus('amadeusCars');
	} else
		return {
			itemPaid: false,
			itemAuthorized: false,
			itemSelected: false,
			itemInCart: false,
		};
}

export const capitalize = (name) => {
	if (name) {
		let newString = [];
		name = name.split(/[ -]+/);
		for (let i in name) {
			let curString = name[i];
			curString = curString.toLowerCase();
			if (curString[0]) {
				let firstLetter = curString[0].toUpperCase();
				let capitalizedWord = firstLetter + curString.slice(1);
				newString.push(capitalizedWord);
			} else {
				return null;
			}
		}
		newString = newString.join(' ');
		return newString;
	} else {
		return null;
	}
};
