import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { connect } from 'react-redux';
import CustomEventWrapper from './CustomEventWrapper';
import HeaderText from './HeaderText';
import CustomDateCellWrapper from './CustomDateCellWrapper';
import {
	returnNewDate,
	returnTrueDate,
	useCheckType,
} from '../../Utilities/helperFunctions';
import { FadeLoader } from 'react-spinners';

import css from '@emotion/css';
import { accrisBodyType, accrisCategory } from '../../Utilities/cars';
const override = css`
	height: 8rem;
	width: 8rem;
	margin: 2rem;
`;
const localizer = momentLocalizer(moment);

let calendarFormats = {
	dayFormat: (date, culture, localizer) => localizer.format(date, 'ddd MMM D'),
};

const ItineraryCalendar = ({ trip, itineraryModalOpen, selected }) => {
	const [windowDimensions, setWindowDimensions] = useState(window.innerWidth);
	const { itineraryItems, returnDate, flightDetails, hotelDetails } = trip;
	const [events, setEvents] = useState([]);
	const [startRange, setStartRange] = useState(null);
	const minTime = new Date(0, 0, 0, 6, 0, 0);
	const typeChecker = useCheckType;
	moment.locale('en');

	useEffect(() => {
		const { selected, authorized, paid, cart, itineraryItems } = trip;
		const handleEditItem = (item) => {
			const { activity, flight, lodging, transportation, custom, amadeusCars } =
				typeChecker(item.type || item.data.type);
			if (custom) {
				return {
					uneditedItem: item,
					start: returnNewDate(item.startDate, item.startTime),
					end: returnNewDate(item.startDate, item.endTime),
					name: item.title,
				};
			}
			if (lodging) {
				const { data, type, _id } = item;
				const { hotel_info, RoomRates } = data;
				const { Rate } =
					RoomRates &&
					RoomRates.length &&
					RoomRates[0]['RoomRate'] &&
					RoomRates[0]['RoomRate'].length &&
					RoomRates[0]['RoomRate'][0]['Rates'] &&
					RoomRates[0]['RoomRate'][0]['Rates'].length &&
					RoomRates[0]['RoomRate'][0]['Rates'][0];
				const { HotelName } = hotel_info && hotel_info.info;
				let policy =
					data &&
					data.descriptive_info &&
					data.descriptive_info['Policies'] &&
					data.descriptive_info['Policies'].length &&
					data.descriptive_info['Policies'][0]['Policy'] &&
					data.descriptive_info['Policies'][0]['Policy'].length
						? data.descriptive_info['Policies'][0]['Policy'].find(
								(item) => item['PolicyInfo']
						  )
						: null;
				let start =
					Rate && Rate.length && Rate[0].info && Rate[0].info.EffectiveDate
						? returnTrueDate(Rate[0].info.EffectiveDate)
						: null;
				policy =
					policy &&
					policy['PolicyInfo'] &&
					policy['PolicyInfo'].length &&
					policy['PolicyInfo'][0].info
						? policy['PolicyInfo'][0].info
						: null;
				const { CheckInTime, CheckOutTime } = policy;
				start.setHours(CheckInTime ? CheckInTime.slice(0, 2) : 0);
				start.setMinutes(CheckInTime ? CheckInTime.slice(3, 5) : 0);
				const rateHasLength = Rate && Rate.length;
				let end = rateHasLength
					? returnTrueDate(
							Rate[Rate.length - 1]['Total']
								? Rate[Rate.length - 2].info.ExpireDate
								: Rate[Rate.length - 1].info.ExpireDate
					  )
					: null;
				end.setHours(CheckOutTime ? CheckOutTime.slice(0, 2) : 0);
				end.setMinutes(CheckOutTime ? CheckOutTime.slice(3, 5) : 0);
				return {
					uneditedItem: item,
					start,
					end,
					name: HotelName,
					_id,
					type,
				};
			}
			if (amadeusCars) {
				let { vehicleRentalPrefType } =
					item.data.vehicleTypeInfo.vehicleCharacteristic;
				const category = accrisCategory[vehicleRentalPrefType[0]];
				const type = accrisBodyType[vehicleRentalPrefType[1]];
				const name = item.data.vehicleTypeInfo.carModel
					? `${item.data.vehicleTypeInfo.carModel}(${vehicleRentalPrefType})`
					: `${category}/${type}`;

				const pickupDropoffTime =
					item && item.data && item.data.pickupDropoffTime;
				let { beginDateTime, endDateTime } = pickupDropoffTime;
				let start =
					beginDateTime &&
					new Date(
						beginDateTime.year,
						parseInt(beginDateTime.month) - 1,
						beginDateTime.day,
						beginDateTime.hour,
						beginDateTime.minutes
					);

				let end =
					endDateTime &&
					new Date(
						endDateTime.year,
						parseInt(endDateTime.month) - 1,
						endDateTime.day,
						endDateTime.hour,
						endDateTime.minutes
					);
				return {
					uneditedItem: item,
					start,
					end,
					name,
				};
			}
			if (activity || transportation) {
				// let startDate =
				// 	item && item.start && item.start instanceof String
				// 		? item.start
				// 		: null;
				// let startHour =
				// 	item && item.start && item.start instanceof String
				// 		? item.start.slice(11, 13)
				// 		: null;
				// let startMin =
				// 	item && item.start && item.start instanceof String
				// 		? item.start.slice(14, 16)
				// 		: null;
				// let endDate =
				// 	item && item.end && item.end instanceof String
				// 		? item.end
				// 		: null;
				// let endHour =
				// 	item && item.end && item.end instanceof String
				// 		? item.end.slice(11, 13)
				// 		: null;
				// let endMin =
				// 	item && item.end && item.end instanceof String
				// 		? item.end.slice(14, 16)
				// 		: null;
				// let start =
				// 	startDate && startHour && startMin
				// 		? returnTrueDate(startDate, startHour, startMin)
				// 		: null;
				// let end =
				// 	endDate && endHour && endMin
				// 		? returnTrueDate(endDate, endHour, endMin)
				// 		: null;

				return { uneditedItem: item, ...item };
			}
			if (flight) {
				const { legs, type } = item.data;
				const flights = legs.map((leg) => {
					const details = leg && leg.flight_details;
					const detailsLength = details && details.length;
					const departure = detailsLength && details[0].departure;
					const arrival = detailsLength && details[detailsLength - 1].arrival;

					return departure && arrival
						? {
								uneditedItem: item,
								start: returnNewDate(departure.date, departure.time),
								end: returnNewDate(arrival.date, arrival.time),
								name: `${departure.location} to ${arrival.location}`,
								_id: item._id,
								type,
						  }
						: null;
				});
				//
				return flights;
			}
		};

		const handleEvents = (itemObject, statusField) => {
			const { flights, lodging, activities, transport, custom, amadeusCars } =
				itemObject;
			const addStatus = (itemField) => {
				const results =
					itemField && itemField.length
						? itemField
								.map((item, index) => {
									const editedItem = handleEditItem(item);
									return Array.isArray(editedItem)
										? editedItem.map((subItem) => {
												return { ...subItem, ...statusField };
										  })
										: {
												...editedItem,
												...statusField,
										  };
								})
								.flat()
						: [];

				return results;
			};

			return [
				...addStatus(flights),
				...addStatus(lodging),
				...addStatus(activities),
				...addStatus(transport),
				...addStatus(custom),
				...addStatus(amadeusCars),
			];
		};

		const selectedItems = handleEvents(selected || {}, { selected: true });
		const authorizedItems = handleEvents(authorized || {}, {
			authorized: true,
		});
		const paidItems = handleEvents(paid || {}, { paid: true });
		const cartItems = handleEvents(cart || {}, { inCart: true });
		const customItems = handleEvents(
			{
				custom:
					itineraryItems.custom.map((item, index) => {
						return { ...item, index };
					}) || [],
			},
			{ selected: true }
		);

		setEvents([
			...customItems,
			...selectedItems,
			...authorizedItems,
			...paidItems,
			...cartItems,
		]);
	}, [
		itineraryItems,
		returnDate,
		flightDetails,
		trip.paid,
		trip.selected,
		trip.authorized,
		trip.cart,
	]);

	useEffect(() => {
		let localStorageDate = localStorage.getItem('calendarDate');

		if (localStorageDate) {
			setStartRange(localStorageDate);
		} else if (
			hotelDetails &&
			hotelDetails.length &&
			hotelDetails[0].startDate
		) {
			const start = returnTrueDate(hotelDetails[0].startDate);
			setStartRange(start);
			localStorage.setItem('calendarDate', start);
		}
		return function cleanup() {
			localStorage.removeItem('calendarDate');
		};
	}, [hotelDetails, windowDimensions]);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(window.innerWidth);
			setWindowDimensions(window.innerWidth);
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<>
			{trip.loading ? (
				<FadeLoader css={override} height={'30%'} width={'7%'} margin={'2%'} />
			) : (
				<Calendar
					formats={calendarFormats}
					events={events.map(({ start, end, ...rest }) => ({
						start: new Date(Date.parse(start)),
						end: new Date(Date.parse(end)),
						...rest,
					}))}
					min={minTime}
					// defaultDate={startRange}
					date={startRange}
					onNavigate={(date) => {
						setStartRange(date);
						localStorage.setItem('calendarDate', date.toISOString());
					}}
					views={['week', 'day']}
					localizer={localizer}
					step={60}
					defaultView={windowDimensions <= 360 ? 'day' : 'week'}
					components={{
						eventWrapper: CustomEventWrapper,
						// event: EventContent,
						timeGutterHeader: HeaderText,
						eventContainerWrapper: ({ children, event }) =>
							CustomDateCellWrapper({
								event,
								children,
								returnDate,
								flightDetails,
								hotelDetails,
							}),
						// timeGridHeader: CustomMultiDayWrapper,
					}}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	itineraryItems: state.trip.itineraryItems,
	returnDate: state.trip.returnDate,
	flightDetails: state.trip.flightDetails,
	hotelDetails: state.trip.hotelDetails,
	trip: state.trip,
	itineraryModalOpen: state.itineraryModal.itineraryModalOpen,
});

export default connect(mapStateToProps, null)(ItineraryCalendar);
