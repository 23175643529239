import React, { useEffect, useRef } from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';

const CheckoutForm = ({ submitAuthorization }) => {
	const stripe = useStripe();
	const elements = useElements();

	const [message, setMessage] = useState(null);
	const [isProcessing, setIsProcessing] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsProcessing(true);

		const { error, paymentIntent } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url: `${window.location.origin}`,
			},
			redirect: 'if_required',
		});

		if (error) {
			setMessage(error.message);
		}

		if (error) {
			setMessage(error.message);
		} else if (paymentIntent && paymentIntent.status === 'succeeded') {
			console.log('paymentIntent', paymentIntent);
			// const succeededPaymentIntent = {
			// 	id: 'pi_3QxgWNITbFuyWpfD1f2geoqK',
			// 	object: 'payment_intent',
			// 	amount: 3500,
			// 	amount_details: {
			// 		tip: {},
			// 	},
			// 	automatic_payment_methods: {
			// 		allow_redirects: 'always',
			// 		enabled: true,
			// 	},
			// 	canceled_at: null,
			// 	cancellation_reason: null,
			// 	capture_method: 'automatic_async',
			// 	client_secret:
			// 		'pi_3QxgWNITbFuyWpfD1f2geoqK_secret_AQ6yqe2btbMJ3jX4yzaigxCJu',
			// 	confirmation_method: 'automatic',
			// 	created: 1740798923,
			// 	currency: 'usd',
			// 	description: null,
			// 	last_payment_error: null,
			// 	livemode: false,
			// 	next_action: null,
			// 	payment_method: 'pm_1QxgYkITbFuyWpfDEvdklKRU',
			// 	payment_method_configuration_details: {
			// 		id: 'pmc_1QuKsvITbFuyWpfD3fMsiMw6',
			// 		parent: null,
			// 	},
			// 	payment_method_types: [
			// 		'card',
			// 		'klarna',
			// 		'link',
			// 		'cashapp',
			// 		'amazon_pay',
			// 	],
			// 	processing: null,
			// 	receipt_email: null,
			// 	setup_future_usage: null,
			// 	shipping: null,
			// 	source: null,
			// 	status: 'succeeded',
			// };

			const curation = {
				payment: {
					id: paymentIntent.id,
					date: new Date(),
					method: 'stripe',
				},
			};

			submitAuthorization(curation);
			setMessage('Payment succeeded');
		}

		setIsProcessing(false);
	};

	return (
		<form className='payment-form' onSubmit={handleSubmit}>
			<PaymentElement id='payment-element' />
			<button disabled={isProcessing || !stripe || !elements} id='submit'>
				<span className='button-text'>
					{isProcessing ? 'Processing ... ' : 'Pay now'}
				</span>
			</button>
			{message && <div id='payment-message'>{message}</div>}
		</form>
	);
};

export default CheckoutForm;
