import React, { useEffect, useState } from 'react';
import ItineraryCategories from '../ItinerarySidenav/ItineraryCategories';
import ItemOptions from '../ItemsOptions/ItemOptions';
import ItineraryCalendar from '../Itinerary/ItineraryCalendar';
import { submitUpdate } from '../../redux/actions/trip';
import { connect } from 'react-redux';

const PlanTripMain = ({
	selected,
	handleSelected,
	toggleModal,
	modalOpen,
	setDetails,
	itemDetails,
	trip,
	submitUpdate,
}) => {
	const [buttonLoading, setButtonLoading] = useState(false);

	const [buttonText, setButtonText] = useState('Save');

	const saveAction = async (e) => {
		e.preventDefault();
		setButtonLoading(true);
		setButtonText('Saving...');
		const saved = await submitUpdate(trip);
		if (saved) {
			setButtonLoading(false);
			setButtonText('Saved');
			setTimeout(() => {
				setButtonText('Save');
			}, 4000);
		}
	};

	return (
		<div className='itinerary-main'>
			<div className='item-nav'>
				<ItineraryCategories
					id={null}
					handleSelected={handleSelected}
					selected={selected}
					showCities={null}
					toggleModal={toggleModal}
					// selected={null}
					modalOpen={modalOpen}
					saveTrip={null}
					toggleState={null}
				/>
				<ItemOptions
					toggleModal={toggleModal}
					modalOpen={modalOpen}
					selected={selected}
					selectedItem={null}
					toggleState={null}
					setDetails={setDetails}
				/>
			</div>

			<div className={`calendar${modalOpen ? ' sidenav-open' : ''}`}>
				<ItineraryCalendar selected={selected} />
				{trip.type === 'agents-choice' ? (
					<button
						className='save-btn'
						disabled={buttonLoading}
						onClick={saveAction}>
						<span>{buttonText}</span>
					</button>
				) : null}
			</div>
		</div>
	);
};

// const mapStateToProps = (state) => ({
// 	trip: state.trip,
// });

export default connect(null, { submitUpdate })(PlanTripMain);
