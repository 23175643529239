import React, { useEffect, useState } from 'react';

const MultidayAutofill = ({
	heightRefs,
	index,
	currentHighlighted,
	setCurrentHighlighted,
	resultIndex,
	handleClick,
	cityName,
	stateCode,
	countryName,
}) => {
	const [isHighlighted, setIsHighlighted] = useState(false);
	useEffect(() => {
		if (currentHighlighted === resultIndex) {
			setIsHighlighted(true);
		} else {
			setIsHighlighted(false);
		}
	}, [currentHighlighted]);
	return (
		<div
			ref={(el) =>
				resultIndex < 5 ? (heightRefs.current[resultIndex] = el) : null
			}
			onMouseOver={(e) => {
				setCurrentHighlighted(resultIndex);
			}}
			onMouseLeave={(e) => {
				setCurrentHighlighted(-1);
			}}
			key={`multicity-search-result-${index}-${resultIndex}`}
			className={`autofill-option${isHighlighted ? ' highlighted' : ''}`}
			onClick={handleClick}>
			<p className='autofill-airport-name capitalize'>
				{cityName},{' '}
				{stateCode && stateCode !== 'null'
					? `${stateCode.toUpperCase()}, `
					: ''}
				{countryName}
			</p>
		</div>
	);
};

export default MultidayAutofill;
